export type NIST_FUNCTION_ROUTE_PARAMS =
  | 'identify'
  | 'protect'
  | 'detect'
  | 'respond'
  | 'recover';

export const IDENTIFY_CATEGORY_LIST = [
  'Asset Management',
  'Business Environment',
  'Governance',
  'Risk Assessment',
  'Risk Management Strategy',
  'Supply Chain Risk Management',
];

export const RECOVER_CATEGORY_LIST = [
  'Recovery Planning',
  'Improvements',
  'Communications',
];

export const RESPOND_CATEGORY_LIST = [
  'Response Planning',
  'Communications',
  'Analysis',
  'Mitigation',
  'Improvements',
];

export const DETECT_CATEGORY_LIST = [
  'Anomalies and Events',
  'Security Continuous Monitoring',
  'Detection Processes',
];
export const PROTECT_CATEGORY_LIST = [
  'Identity Management and Access Control',
  'Awareness and Training',
  'Data Security',
  'Information Protection Processes and Procedures',
  'Maintenance',
  'Protective Technology',
];

export const NIST_FUNCTION_ACRONYMS = {
  identify: {
    tag: 'ID',
    uppercase: 'Identify',
    categoryList: IDENTIFY_CATEGORY_LIST,
  },
  protect: {
    tag: 'PR',
    uppercase: 'Protect',
    categoryList: PROTECT_CATEGORY_LIST,
  },
  detect: {
    tag: 'DE',
    uppercase: 'Detect',
    categoryList: DETECT_CATEGORY_LIST,
  },
  recover: {
    tag: 'RC',
    uppercase: 'Recover',
    categoryList: RECOVER_CATEGORY_LIST,
  },
  respond: {
    tag: 'RS',
    uppercase: 'Respond',
    categoryList: RESPOND_CATEGORY_LIST,
  },
};
