import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { RootState } from '../../redux/store';
import {
  Box,
  Toolbar,
  Container,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Button,
} from '@mui/material';
import { PlagiarismOutlined, FileDownload } from '@mui/icons-material';
import styled from 'styled-components';
import { isEmpty, isEqual, map } from 'lodash';
import { fetchPolicies } from '../../redux/policySlice';
import { createPresignedURL } from '../../redux/downloadPolicySlice';
import Title from '../../components/Title';

const StyledRow = styled(TableRow)<{ removed: string }>`
  && {
    background-color: ${({ removed }) =>
      isEqual(removed, 'REMOVED') ? 'rgba(224, 224, 224, 0.5)' : 'transparent'};
  }
`;

function MyTableRow({
  removed,
  children,
}: {
  removed: string;
  children: React.ReactNode;
}) {
  return <StyledRow removed={removed}>{children}</StyledRow>;
}

const DataRoom = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const selectedOrganization = useSelector(
    (state: RootState) => state.organizations.selectedOrganization
  );

  const downloadingPolicy = useSelector(
    (state: RootState) => state.downloadPolicy.policyId
  );

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(
        fetchPolicies({ organizationId: selectedOrganization.id, accessToken })
      );

      if (!isMounted) {
        return;
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, selectedOrganization]);

  const policies = useSelector((state: RootState) => state.policies.value);
  const policiesStatus = useSelector(
    (state: RootState) => state.policies.status
  );

  const handleViewPolicyDetail = async (policyId: string) => {
    console.log(`Send to /dataroom/${policyId}`);
    navigate(`/dataRoom/${policyId}`);
  };

  const handleDownloadPolicy = async (policyId: string) => {
    console.log(`handleDownloadPolicy`);
    const accessToken = await getAccessTokenSilently();

    dispatch(
      createPresignedURL({
        organizationId: selectedOrganization.id,
        policyId,
        accessToken,
      })
    );
  };

  const formattedDate = (date: Date) => {
    return `${date.getMonth() + 1}/${date
      .getDate()
      .toString()
      .padStart(2, '0')}/${date.getFullYear().toString().slice(2)}`;
  };

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />

      <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ padding: '20px', margin: '10px' }}>
          <Title>Data Room</Title>
        </Paper>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                marginTop: '50px',
              }}
            >
              {policiesStatus === 'loading' ? (
                <CircularProgress />
              ) : policiesStatus === 'failed' ? (
                'Error loading policies.'
              ) : isEmpty(policies) ? (
                'No policies found for the selected organization.'
              ) : (
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Analysis Status</TableCell>
                      <TableCell>Uploaded</TableCell>
                      <TableCell>Detail</TableCell>
                      <TableCell>Download</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(Object.values(policies), (row: any) => (
                      <MyTableRow removed={row.statusFlag} key={row.id}>
                        <TableCell>{row.policyName}</TableCell>
                        <TableCell>
                          {row.analysisStatus === 'COMPLETE' ? (
                            <Button
                              variant='contained'
                              disabled
                              style={{
                                marginRight: '10px',
                                backgroundColor: '#4caf50', // Custom background color
                                color: 'white', // Custom text color
                                opacity: 0.5, // To indicate that the button is disabled
                              }}
                            >
                              {row.analysisStatus}
                            </Button>
                          ) : (
                            <Button
                              variant='contained'
                              disabled
                              style={{
                                marginRight: '10px',
                                backgroundColor: '#1976D2', // Custom background color
                                color: 'white', // Custom text color
                                opacity: 0.5, // To indicate that the button is disabled
                              }}
                            >
                              {row.analysisStatus}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          {formattedDate(new Date(row.created))}
                        </TableCell>
                        <TableCell>
                          <PlagiarismOutlined
                            onClick={() => handleViewPolicyDetail(row.id)}
                          />
                        </TableCell>
                        <TableCell>
                          {isEqual(downloadingPolicy, row.id) ? (
                            <CircularProgress />
                          ) : (
                            <FileDownload
                              onClick={() => handleDownloadPolicy(row.id)}
                            />
                          )}
                        </TableCell>
                      </MyTableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default DataRoom;
