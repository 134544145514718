import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import {
  Box,
  InputLabel,
  MenuItem,
  CircularProgress,
  FormControl,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { isEmpty, isEqual, map } from 'lodash';
import { Organization } from '../core';
import {
  fetchOrganizations,
  setSelectedOrganization,
} from '../redux/organizationsSlice';
import { useAuth0 } from '@auth0/auth0-react';

export default function BasicSelect() {
  const { getAccessTokenSilently, isLoading } = useAuth0();

  React.useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(fetchOrganizations({ accessToken }));

      if (!isMounted) {
        return;
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);
  const dispatch = useDispatch();

  const organizationsList = useSelector(
    (state: RootState) => state.organizations.list
  );

  const selectedOrg = useSelector(
    (state: RootState) => state.organizations.selectedOrganization
  );

  const organizationsStatus = useSelector(
    (state: RootState) => state.organizations.status
  );

  const handleChange = (event: SelectChangeEvent) => {
    const selectedOrganizationId = event.target.value as string;

    if (selectedOrganizationId !== selectedOrg.id) {
      dispatch(
        setSelectedOrganization(organizationsList[selectedOrganizationId])
      );
    }
  };

  return (
    <Box sx={{ minWidth: 300 }}>
      {isEqual(organizationsStatus, 'loading') && !isEmpty(selectedOrg) ? (
        <CircularProgress />
      ) : (
        <FormControl fullWidth sx={{ background: 'white', color: 'blue' }}>
          <InputLabel id='demo-simple-select-label'></InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={selectedOrg.id}
            label={selectedOrg.name}
            onChange={handleChange}
          >
            {map(organizationsList, (organization: Organization) => (
              <MenuItem key={organization.id} value={organization.id}>
                {organization.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
