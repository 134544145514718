import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { listOrganizations } from '../client/organization';
import { STATUS } from '../core';
import { loadFromLocalStorage, saveToLocalStorage } from '../utils/helpers';
export interface ListOrgsPayload {
  accessToken: string;
}
export const fetchOrganizations: any = createAsyncThunk(
  'organizations/fetchOrganizations',
  async (payload: ListOrgsPayload) => {
    const response = await listOrganizations(payload);
    const data = await response.data;
    return data;
  }
);

export interface OrganizationState {
  list: {
    [key: string]: any;
  };
  selectedOrganization: any;
  status: string;
}
const initialState: OrganizationState = {
  list: {},
  selectedOrganization: loadFromLocalStorage('lastOrganization') || {
    id: '339bdc96-9409-475c-8712-58ee39fdb7cd',
    name: 'Test Customer',
    status: STATUS.ACTIVE,
  },
  status: 'idle',
};

export const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
      saveToLocalStorage('lastOrganization', action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      // List
      .addCase(fetchOrganizations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.status = 'failed';
        state.list = action.payload;
      });
  },
});

export const { setSelectedOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;
