import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { map, pick, size } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlFindings from './ControlFindings';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CategoryTabs({
  categories,
  controls,
  requirements,
  answers,
}: {
  categories: string[];
  controls: any[];
  requirements: any;
  answers: any;
}) {
  const [value, setValue] = React.useState(0);
  console.log(
    'BATMAN',
    pick(answers, [
      '6679de08-9d36-4597-a089-408321baab5a',
      'a565d672-c596-4fec-8002-e91059ef8f35',
      'ffb2120d-258b-4dcf-8e48-ef03617c72c0',
      '04f365c8-356c-4418-ab49-be35baeff5a3',
      '68be5dcc-d077-48f2-8326-3eb49d2504dd',
      'f2d79fbe-2c29-4b4f-b64c-77aff5e98c56',
      'f5521d87-1fbd-4ef8-843a-b6f7fbe1f510',
      '40a9069f-ce97-4372-83ee-ea04d8801f18',
      '29388a9b-ebe0-4947-8e57-aeb8a3eda6bb',
      '5053715e-9005-469b-a85a-a8a7c60484dd',
      '2fdea3e2-fd04-4e58-a51f-a065bc3b2be0',
      'e65dae06-2f77-45f7-9232-a07f7bada7a0',
      'de2381ab-f7c4-4332-9343-706048fb532a',
    ])
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          {map(categories, (categoryName: string, index: number) => {
            return <Tab label={categoryName} {...a11yProps(index)} />;
          })}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {map(controls[value], (control: any, controlIndex: number) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Button
                variant='contained'
                disabled
                style={{
                  marginRight: '10px',
                  backgroundColor: '#4caf50',
                  color: 'white',
                  opacity: 0.5,
                }}
              >
                {control.nistSubcategory}
              </Button>

              <Typography>{control.question}</Typography>
              <Button
                variant='contained'
                disabled
                style={{
                  marginLeft: 'auto',
                  marginRight: '25px',
                  backgroundColor: 'blue',
                  color: 'white',
                  opacity: 0.5,
                }}
              >
                {Object.values(pick(answers, control.requirementIds)).length}{' '}
                out of {control.totalRequirements}
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <ControlFindings
                answers={pick(answers, control.requirementIds)}
                control
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        {map(controls[value], (control: any, controlIndex: number) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Button
                variant='contained'
                disabled
                style={{
                  marginRight: '10px',
                  backgroundColor: '#4caf50', // Custom background color
                  color: 'white', // Custom text color
                  opacity: 0.5, // To indicate that the button is disabled
                }}
              >
                {control.nistSubcategory}
              </Button>

              <Typography>{control.question}</Typography>
              <Button
                variant='contained'
                disabled
                style={{
                  marginLeft: 'auto',
                  marginRight: '25px',
                  backgroundColor: 'blue',
                  color: 'white',
                  opacity: 0.5,
                }}
              >
                {Object.values(pick(answers, control.requirementIds)).length}{' '}
                out of {control.totalRequirements}
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <ControlFindings
                answers={pick(answers, control.requirementIds)}
                control
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        {map(controls[value], (control: any, controlIndex: number) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Button
                variant='contained'
                disabled
                style={{
                  marginRight: '10px',
                  backgroundColor: '#4caf50', // Custom background color
                  color: 'white', // Custom text color
                  opacity: 0.5, // To indicate that the button is disabled
                }}
              >
                {control.nistSubcategory}
              </Button>

              <Typography>{control.question}</Typography>
              <Button
                variant='contained'
                disabled
                style={{
                  marginLeft: 'auto',
                  marginRight: '25px',
                  backgroundColor: 'blue',
                  color: 'white',
                  opacity: 0.5,
                }}
              >
                {Object.values(pick(answers, control.requirementIds)).length}{' '}
                out of {control.totalRequirements}
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <ControlFindings
                answers={pick(answers, control.requirementIds)}
                control
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        {map(controls[value], (control: any, controlIndex: number) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Button
                variant='contained'
                disabled
                style={{
                  marginRight: '10px',
                  backgroundColor: '#4caf50', // Custom background color
                  color: 'white', // Custom text color
                  opacity: 0.5, // To indicate that the button is disabled
                }}
              >
                {control.nistSubcategory}
              </Button>

              <Typography>{control.question}</Typography>
              <Button
                variant='contained'
                disabled
                style={{
                  marginLeft: 'auto',
                  marginRight: '25px',
                  backgroundColor: 'blue',
                  color: 'white',
                  opacity: 0.5,
                }}
              >
                {Object.values(pick(answers, control.requirementIds)).length}{' '}
                out of {control.totalRequirements}
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <ControlFindings
                answers={pick(answers, control.requirementIds)}
                control
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        {map(controls[value], (control: any, controlIndex: number) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Button
                variant='contained'
                disabled
                style={{
                  marginRight: '10px',
                  backgroundColor: '#4caf50', // Custom background color
                  color: 'white', // Custom text color
                  opacity: 0.5, // To indicate that the button is disabled
                }}
              >
                {control.nistSubcategory}
              </Button>

              <Typography>{control.question}</Typography>
              <Button
                variant='contained'
                disabled
                style={{
                  marginLeft: 'auto',
                  marginRight: '25px',
                  backgroundColor: 'blue',
                  color: 'white',
                  opacity: 0.5,
                }}
              >
                {Object.values(pick(answers, control.requirementIds)).length}{' '}
                out of {control.totalRequirements}
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <ControlFindings
                answers={pick(answers, control.requirementIds)}
                control
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={5}>
        {map(controls[value], (control: any, controlIndex: number) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Button
                variant='contained'
                disabled
                style={{
                  marginRight: '10px',
                  backgroundColor: '#4caf50', // Custom background color
                  color: 'white', // Custom text color
                  opacity: 0.5, // To indicate that the button is disabled
                }}
              >
                {control.nistSubcategory}
              </Button>

              <Typography>{control.question}</Typography>
              <Button
                variant='contained'
                disabled
                style={{
                  marginLeft: 'auto',
                  marginRight: '25px',
                  backgroundColor: 'blue',
                  color: 'white',
                  opacity: 0.5,
                }}
              >
                {Object.values(pick(answers, control.requirementIds)).length}{' '}
                out of {control.totalRequirements}
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <ControlFindings
                answers={pick(answers, control.requirementIds)}
                control
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </CustomTabPanel>
    </Box>
  );
}
