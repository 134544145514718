import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listMetrics } from '../client/controlAnswer';

export interface MetricsState {
  value: { [key: string]: any };
  status: string;
}

export const initialMetricsState: MetricsState = {
  value: {
    functions: {
      Identify: {
        name: 'Identify',
        mitigated: 0,
        unmitigated: 0,
        total: 0,
        riskMitigated: 0,
        riskUnmitigated: 0,
        riskTotal: 0,
      },
      Protect: {
        name: 'Protect',
        mitigated: 0,
        unmitigated: 0,
        total: 0,
        riskMitigated: 0,
        riskUnmitigated: 0,
        riskTotal: 0,
      },
      Detect: {
        name: 'Detect',
        mitigated: 0,
        unmitigated: 0,
        total: 0,
        riskMitigated: 0,
        riskUnmitigated: 0,
        riskTotal: 0,
      },
      Respond: {
        name: 'Respond',
        mitigated: 0,
        unmitigated: 0,
        total: 0,
        riskMitigated: 0,
        riskUnmitigated: 0,
        riskTotal: 0,
      },
      Recover: {
        name: 'Recover',
        mitigated: 0,
        unmitigated: 0,
        total: 0,
        riskMitigated: 0,
        riskUnmitigated: 0,
        riskTotal: 0,
      },
    },
    totals: {
      mitigatedControls: 0,
      unmitigatedControls: 0,
      controls: 0,
      riskMitigated: 0,
      riskUnmitigated: 0,
      risk: 0,
    },
  },
  status: 'idle',
};
export interface FetchMetricsPayload {
  organizationId: string;
  accessToken: any;
}

export const fetchMetrics: any = createAsyncThunk(
  'metrics/fetch',
  async (payload: FetchMetricsPayload) => {
    const response = await listMetrics(payload);

    const data = await response.data;
    return data;
  }
);

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState: initialMetricsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // List
      .addCase(fetchMetrics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchMetrics.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload;
      });
  },
});

export default metricsSlice.reducer;
