import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ListPoliciesPayload, listPolicies } from '../client/policy';

export const fetchPolicies: any = createAsyncThunk(
  'policies/fetch',
  async (payload: ListPoliciesPayload) => {
    const response = await listPolicies(payload);
    const data = await response.data;
    return data;
  }
);

export interface PolicyState {
  value: { [key: string]: any };
  status: string;
}
export const initialPolicyState: PolicyState = {
  value: {},
  status: 'idle',
};

export const policySlice = createSlice({
  name: 'policies',
  initialState: initialPolicyState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // List
      .addCase(fetchPolicies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPolicies.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchPolicies.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload;
      });
  },
});

export default policySlice.reducer;
