import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreatePresignedURLPayload } from '../client/s3';
import { createPresignedURL as CreatePresignedURL } from '../client/s3';

export const createPresignedURL: any = createAsyncThunk(
  'downloadPolicy/createPresignedURL',
  async (payload: CreatePresignedURLPayload) => {
    const response = await CreatePresignedURL(payload);
    return response.data;
  },
  {
    getPendingMeta: ({ arg }) => ({ policyId: arg.policyId }),
  }
);

export interface DownloadPolicyState {
  policyId: string;
  status: string;
}
export const initialDownloadPolicyState: DownloadPolicyState = {
  policyId: '',
  status: 'idle',
};

export const downloadPolicySlice = createSlice({
  name: 'downloadPolicy',
  initialState: initialDownloadPolicyState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPresignedURL.pending, (state, action) => {
        state.status = 'loading';
        state.policyId = action.meta.policyId;
      })
      .addCase(createPresignedURL.fulfilled, (state, action) => {
        console.log('SUCCESSFUL DOWQNLAOD');

        state.status = 'idle';
        state.policyId = '';
      })
      .addCase(createPresignedURL.rejected, (state) => {
        console.log('FAILED TO DOWQNLAOD');
        state.status = 'failed';
        state.policyId = '';
      });
  },
});

export default downloadPolicySlice.reducer;
