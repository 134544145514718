import axios from 'axios';
import { API_URL } from './models';
import { FetchMetricsPayload } from '../redux/metricsSlice';
import { isBoolean, isEmpty, isEqual, isString } from 'lodash';

export interface ListFindingsPayload {
  accessToken: string;
  organizationId: string;
  filters: { nistFunction?: string; policyId?: string; mitigated?: string };
}

export const ListFindings = async ({
  organizationId,
  accessToken,
  filters,
}: ListFindingsPayload): Promise<any> => {
  try {
    const params = new URLSearchParams();
    if (isString(filters.nistFunction) && !isEmpty(filters.nistFunction)) {
      params.append('nistFunction', filters.nistFunction);
    }

    if (isString(filters.policyId) && !isEmpty(filters.policyId)) {
      params.append('policyId', filters.policyId);
    }

    if (isString(filters.mitigated) && isEqual(filters.mitigated, 'true')) {
      params.append('mitigated', filters.mitigated);
    }
    console.log('PARAMS PASSED: ', params.toString());
    const config = {
      method: 'get',
      url: `${API_URL}/organization/${organizationId}/controlAnswer?${params.toString()}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(config);

    return { success: true, data: response.data };
  } catch (error: any) {
    if (error.name === 'AxiosError') {
      return { success: false, data: error.response.data };
    } else {
      console.log(error);
      return {
        success: false,
        data: {
          name: 'UnknownError',
          message: 'listControlAnswers failed with unknown error',
        },
      };
    }
  }
};

export const listMetrics = async ({
  organizationId,
  accessToken,
}: FetchMetricsPayload): Promise<any> => {
  try {
    console.log('listMetrics', organizationId);

    const config = {
      method: 'get',
      url: `${API_URL}/organization/${organizationId}/controlAnswer/metrics`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(config);

    return { success: true, data: response.data };
  } catch (error: any) {
    if (error.name === 'AxiosError') {
      return { success: false, data: error.response.data };
    } else {
      console.log(error);
      return {
        success: false,
        data: {
          name: 'UnknownError',
          message: 'listMetrics failed with unknown error',
        },
      };
    }
  }
};
