import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SecurityIcon from '@mui/icons-material/Security';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  BrowserRouter,
} from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';

export function Router(props: { children?: React.ReactNode }) {
  const { children } = props;
  if (typeof window === 'undefined') {
    return <StaticRouter location='/'>{children}</StaticRouter>;
  }

  return <BrowserRouter>{children}</BrowserRouter>;
}

export interface ListItemButtonLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  function Link(itemProps, ref) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
  }
);

function ListItemLink(props: ListItemButtonLinkProps) {
  const { icon, primary, to } = props;

  return (
    <li>
      <ListItemButton component={Link} to={to}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  );
}

export const mainListItems = (
  <React.Fragment>
    <ListItemLink to='/' primary='Dashboard' icon={<DashboardIcon />} />
    <ListItemLink
      to='/dataRoom'
      primary='Data Room'
      icon={<SourceOutlinedIcon />}
    />
    <ListItemLink to='/identify' primary='Identify' icon={<AssignmentIcon />} />
    <ListItemLink to='/protect' primary='Protect' icon={<SecurityIcon />} />
    <ListItemLink to='/detect' primary='Detect' icon={<EngineeringIcon />} />
    <ListItemLink
      to='/respond'
      primary='Respond'
      icon={<SourceOutlinedIcon />}
    />
    <ListItemLink
      to='/recover'
      primary='Recover'
      icon={<HealthAndSafetyIcon />}
    />
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component='div' inset>
      AI-Assist Appendix
    </ListSubheader>
  </React.Fragment>
);
