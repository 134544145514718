import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

function EvidenceComponent({
  source,
  text,
  snippetNumber,
}: {
  source: string;
  text: string;
  snippetNumber: number;
}) {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: '8px 16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        backgroundColor: '#f5f5f5',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        marginBottom: '8px',
      }}
    >
      {/* <Box sx={{ flexShrink: 0 }}>
        <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
          Snippet: {snippetNumber}
        </Typography>
      </Box> */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
          Evidence #{snippetNumber + 1}
        </Typography>
        <Typography variant='subtitle2'>{text}</Typography>
      </Box>
    </Paper>
  );
}

export default EvidenceComponent;
