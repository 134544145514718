import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';
import Title from '../../components/Title';

// const data = [
//   {
//     name: 'Identify',
//     mitigated: 52,
//     unmitigated: 14,
//     // amt: 2400,
//   },
//   {
//     name: 'Protect',
//     mitigated: 17,
//     unmitigated: 65,
//     // amt: 2210,
//   },
//   {
//     name: 'Governance',
//     mitigated: 45,
//     unmitigated: 27,
//     // amt: 2290,
//   },
//   {
//     name: 'Response',
//     mitigated: 12,
//     unmitigated: 14,
//     // amt: 2000,
//   },
//   {
//     name: 'Recover',
//     mitigated: 3,
//     unmitigated: 25,
//     // amt: 2181,
//   },
// ];

export default function Chart({ data }: { data: any }) {
  // const theme = useTheme();
  console.log(data);
  return (
    <React.Fragment>
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey='unmitigated' stackId='a' fill='#8884d8' />
          <Bar dataKey='mitigated' stackId='a' fill='#82ca9d' />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
