import { Auth0Provider, AppState } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithNavigate = ({
  children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null => {
  const navigate = useNavigate();

  const domain = 'bl-portal-dev.us.auth0.com';
  const clientId = 'L33xZzFVKeeSgLp3x7SbnEPuGKWOEIPC';
  const redirectUri = window.location.hostname.includes('aia.prod')
    ? 'https://aia.prod.zyston.com/callback'
    : window.location.hostname.includes('aia-dev')
    ? 'https://aia-dev.comply.cloud/callback'
    : 'http://localhost:3000/callback';
  const audience =
    // window.location.hostname.includes('aia.prod')
    // ? 'https://aia.prod.zyston.com'
    // :
    'https://aia-dev.comply.cloud';

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
