import axios from 'axios';
import { API_URL } from './models';

export interface ListPoliciesPayload {
  organizationId: string;
  accessToken: string;
}

export const listPolicies = async ({
  organizationId,
  accessToken,
}: ListPoliciesPayload): Promise<any> => {
  console.log('CLIENT:listPolicies');

  const config = {
    method: 'get',
    url: `${API_URL}/organization/${organizationId}/policy`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(config);

    return { success: true, data: response.data };
  } catch (error: any) {
    if (error.name === 'AxiosError') {
      return { success: false, data: error.response.data };
    }
  }
};
