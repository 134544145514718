import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import RestoreIcon from '@mui/icons-material/Restore';
import SecurityIcon from '@mui/icons-material/Security';
import SearchIcon from '@mui/icons-material/Search';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SensorsIcon from '@mui/icons-material/Sensors';
import { Grid } from '@mui/material';

interface SalesCardProps {
  totalSales: number;
  outOf: number;
  title: string;
  gradient: string;
}
const ICONS: any = {
  Identify: <SearchIcon sx={{ fontSize: 40 }} />,
  Protect: <SecurityIcon sx={{ fontSize: 40 }} />,
  Detect: <SensorsIcon sx={{ fontSize: 40 }} />,
  Respond: <ChatBubbleOutlineIcon sx={{ fontSize: 40 }} />,
  Recover: <RestoreIcon sx={{ fontSize: 40 }} />,
};
const getIcon = (title: string) => {
  return ICONS[title];
};

const SalesCard: React.FC<SalesCardProps> = ({
  totalSales,
  title,
  gradient,
  outOf,
}) => {
  return (
    <Card
      sx={{
        // width: '300px',
        background: `${gradient}`,
        color: 'white',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        borderRadius: 3,
        padding: '0 30px',
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant='h5' component='div'>
              {title}
            </Typography>
            <Typography variant='h3' component='div'>
              {totalSales}
            </Typography>
            <Typography variant='body2' component='div'>
              Out of {outOf}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            container
            justifyContent='center'
            alignItems='center'
          >
            {getIcon(title)}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SalesCard;
