import axios from 'axios';
import { API_URL } from './models';
import { ControlFilters } from '../redux/controlSlice';

export const listControls = async ({
  accessToken,
  nistFunction,
}: ControlFilters): Promise<any> => {
  try {
    console.log('listControls');
    const params = new URLSearchParams();
    if (nistFunction) {
      params.append('nistFunction', nistFunction);
    }

    const config = {
      method: 'get',
      url: `${API_URL}/control?${params.toString()}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(config);

    return { success: true, data: response.data };
  } catch (error: any) {
    if (error.name === 'AxiosError') {
      return { success: false, data: error.response.data };
    } else {
      console.log(error);
      return {
        success: false,
        data: {
          name: 'UnknownError',
          message: 'listControls failed with unknown error',
        },
      };
    }
  }
};
