import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createRoot } from 'react-dom/client';
import App from './App';
import store from './redux/store';
import { Provider } from 'react-redux';
import { Router } from './pages/dashboard/listItems';
import { Auth0ProviderWithNavigate } from './components/auth/Auth0ProviderWithNavigate';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.Fragment>
    <Router>
      <Auth0ProviderWithNavigate>
        <Provider store={store}>
          <CssBaseline />
          <App />
        </Provider>
      </Auth0ProviderWithNavigate>
    </Router>
  </React.Fragment>
);
