import { map } from 'lodash';

import RequirementFindings from './RequirementFindings';

export default function ControlFindings({
  answers,
  control,
}: {
  answers: any;
  control: any;
}) {
  // console.log('ControlFindings', answers);
  return (
    <>
      {map(answers, (value: any, key: any) => {
        return <RequirementFindings answers={value} />;
      })}
    </>
  );
}
