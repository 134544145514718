import { withAuthenticationRequired } from '@auth0/auth0-react';
import { CircularProgress } from '@mui/material';

export const AuthenticationGuard = ({ component }: any) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className='page-layout'>
        <CircularProgress />
      </div>
    ),
  });

  return <Component />;
};
