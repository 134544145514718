import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listControls } from '../client/control';

export interface ControlFilters {
  nistFunction?: string;
  accessToken: string;
}

export const fetchControls: any = createAsyncThunk(
  'controls/fetchControls',
  async (payload: ControlFilters) => {
    const response = await listControls(payload);
    const data = await response.data;
    return data;
  }
);

export interface MyControlState {
  value: { [key: string]: any };
  status: string;
}
const initialState: MyControlState = {
  value: {},
  status: 'idle',
};

export const controlSlice = createSlice({
  name: 'controls',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // List
      .addCase(fetchControls.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchControls.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchControls.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload;
      });
  },
});

export default controlSlice.reducer;
