import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listRequirements } from '../client/requirement';

export interface RequirementFilters {
  nistFunction?: string;
  accessToken: string;
}
export const fetchRequirements: any = createAsyncThunk(
  'requirements/fetchRequirements',
  async (payload: RequirementFilters) => {
    const response = await listRequirements(payload);
    const data = await response.data;
    return data;
  }
);

export interface MyRequirementsState {
  value: { [key: string]: any };
  status: string;
}
const initialRequirementsState: MyRequirementsState = {
  value: {},
  status: 'idle',
};

export const requirementsSlice = createSlice({
  name: 'requirements',
  initialState: initialRequirementsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // List
      .addCase(fetchRequirements.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRequirements.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchRequirements.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload;
      });
  },
});

export default requirementsSlice.reducer;
