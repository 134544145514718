import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import {
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from './pages/dashboard/listItems';
import Dashboard from './pages/dashboard/Dashboard';
import DataRoom from './pages/dataRoom/DataRoom';
import PolicyDetail from './pages/dataRoom/PolicyDetail';
import SimpleMenu from './components/SimpleMenu';
import { AuthenticationGuard } from './components/auth/AuthenticationGuard';
import { LoadingPage } from './components/LoadingPage';
import LogoutButton from './components/LogoutButton';
import AggregateFindings from './pages/aggregateFindings/AggregateFindings';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function App() {
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position='absolute' open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            sx={{ flexGrow: 1 }}
          >
            AI-Assist
          </Typography>
          <SimpleMenu />
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component='nav'>
          {mainListItems}
          <Divider sx={{ my: 1 }} />
          {secondaryListItems}
        </List>
      </Drawer>
      <Routes>
        <Route
          path='/'
          element={<AuthenticationGuard component={Dashboard} />}
        />

        <Route
          path='/dataRoom'
          element={<AuthenticationGuard component={DataRoom} />}
        />
        <Route
          path='/dataRoom/:id'
          element={<AuthenticationGuard component={PolicyDetail} />}
        />

        <Route
          path='/:nistFunction'
          element={<AuthenticationGuard component={AggregateFindings} />}
        />
        <Route
          path='/:nistFunction'
          element={<AuthenticationGuard component={AggregateFindings} />}
        />
        <Route
          path='/:nistFunction'
          element={<AuthenticationGuard component={AggregateFindings} />}
        />
        <Route
          path='/:nistFunction'
          element={<AuthenticationGuard component={AggregateFindings} />}
        />
        <Route
          path='/:nistFunction'
          element={<AuthenticationGuard component={AggregateFindings} />}
        />

        <Route
          path='/callback'
          element={<AuthenticationGuard component={LoadingPage} />}
        />
      </Routes>
    </Box>
  );
}
