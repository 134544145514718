import axios from 'axios';
import { API_URL } from './models';
import {
  ListOrgsPayload,
  organizationSlice,
} from '../redux/organizationsSlice';

export interface GetOrganizationPayload {
  organizationId: string;
}
export const listOrganizations = async ({
  accessToken,
}: ListOrgsPayload): Promise<any> => {
  try {
    console.log('listOrganizations');

    const config = {
      method: 'get',
      url: `${API_URL}/organization`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(config);

    return { success: true, data: response.data };
  } catch (error: any) {
    if (error.name === 'AxiosError') {
      return { success: false, data: error.response.data };
    } else {
      console.log(error);
      return {
        success: false,
        data: {
          name: 'UnknownError',
          message: 'listOrganizations failed with unknown error',
        },
      };
    }
  }
};

//
