import { configureStore } from '@reduxjs/toolkit';
import controlAnswerReducer from './controlAnswerSlice';
import controlReducer from './controlSlice';
import policiesReducer from './policySlice';
import uploadURLReducer from './uploadURLSlice';
import metricsReducer from './metricsSlice';
import requirementsReducer from './requirementsSlice';
import organizationsReducer from './organizationsSlice';
import downloadPolicyReducer from './downloadPolicySlice';

const store = configureStore({
  reducer: {
    controls: controlReducer,
    policies: policiesReducer,
    controlAnswers: controlAnswerReducer,
    uploadURL: uploadURLReducer,
    metrics: metricsReducer,
    requirements: requirementsReducer,
    organizations: organizationsReducer,
    downloadPolicy: downloadPolicyReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
