import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { fetchControlAnswers } from '../../redux/controlAnswerSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { filter } from 'lodash';
import Title from '../../components/Title';
import SortedTable from '../../components/SortedTable';

export default function PolicyDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  // TODO should get policy by id in a useEffect so hard refresh doesn't crash app
  const policy = useSelector(
    (state: RootState) => state.policies.value[id as string]
  );

  const selectedOrg = useSelector(
    (state: RootState) => state.organizations.selectedOrganization
  );

  const controlAnswers = useSelector((state: RootState) => {
    const controlsArray = Object.values(state.controlAnswers.value);
    const policyControlAnswers = filter(controlsArray, {
      policyId: policy.id,
    });
    return policyControlAnswers;
  });

  const controlAnswersStatus = useSelector(
    (state: RootState) => state.controlAnswers.status
  );

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();

      dispatch(
        fetchControlAnswers({ organizationId: selectedOrg.id, accessToken })
      );

      if (!isMounted) {
        return;
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, selectedOrg]);

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 100,
            marginBottom: '50px',
            marginTop: '75px',
          }}
        >
          <Title>Policy Findings</Title>
          <Typography variant='body1' color='text.secondary'>
            {policy.policyName}
          </Typography>
        </Paper>
        {controlAnswersStatus === 'loading' ? (
          'Loading data'
        ) : controlAnswersStatus === 'failed' ? (
          'Error loading data.'
        ) : (
          <Box>
            <SortedTable rows={controlAnswers} />
          </Box>
        )}
      </Container>
    </Box>
  );
}
