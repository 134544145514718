import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  NIST_FUNCTION_ACRONYMS,
  NIST_FUNCTION_ROUTE_PARAMS,
} from '../../core/nistV1.1';
import { fetchControls } from '../../redux/controlSlice';
import { fetchControlAnswers } from '../../redux/controlAnswerSlice';
import { fetchRequirements } from '../../redux/requirementsSlice';
import { Box, Paper, Typography } from '@mui/material';
import { groupBy, isEqual } from 'lodash';
import CategoryTabs from '../../components/CategoryTabs';
import Title from '../../components/Title';

export default function AggregateFindings({}: {}) {
  const { nistFunction } = useParams();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const FUNCTION_TAGS =
    NIST_FUNCTION_ACRONYMS[nistFunction as NIST_FUNCTION_ROUTE_PARAMS];

  console.log({ uppercase: FUNCTION_TAGS.uppercase });
  const selectedOrg = useSelector(
    (state: RootState) => state.organizations.selectedOrganization
  );

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(fetchControls({ nistFunction: FUNCTION_TAGS.tag, accessToken }));
      dispatch(
        fetchControlAnswers({
          organizationId: selectedOrg.id,
          filters: { mitigated: 'true', nistFunction: FUNCTION_TAGS.uppercase },
          accessToken,
        })
      );
      dispatch(
        fetchRequirements({
          nistFunction: FUNCTION_TAGS.uppercase,
          accessToken,
        })
      );
      if (!isMounted) {
        return;
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, selectedOrg, nistFunction]);

  const controls = useSelector(
    (state: RootState) => state.controls.value
    // groupBy(Object.values(state.controls.value), 'nistCategory')
  );
  const controlsStatus = useSelector(
    (state: RootState) => state.controls.status
  );

  const requirements = useSelector(
    (state: RootState) => state.requirements.value
  );
  const requirementsStatus = useSelector(
    (state: RootState) => state.requirements.status
  );

  const requirementAnswers = useSelector(
    (state: RootState) => state.controlAnswers.value
  );
  const requirementAnswersStatus = useSelector(
    (state: RootState) => state.controlAnswers.status
  );

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          width: '90%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 75,
            marginTop: '75px',
            marginBottom: '50px',
          }}
        >
          <Title>{FUNCTION_TAGS.uppercase}</Title>
        </Paper>
        {isEqual(controlsStatus, 'loading') ||
        isEqual(requirementsStatus, 'loading') ||
        isEqual(requirementAnswersStatus, 'loading') ? (
          <Paper>
            <Typography>Data is loading</Typography>
          </Paper>
        ) : (
          <CategoryTabs
            categories={FUNCTION_TAGS.categoryList}
            controls={Object.values(
              groupBy(Object.values(controls), 'nistCategory')
            )}
            requirements={requirements}
            answers={groupBy(
              Object.values(requirementAnswers),
              'requirementId'
            )}
          />
        )}
      </Box>
    </Box>
  );
}
