import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

interface AggregateCardProps {
  title: string;
  value: number | string;
  icon: React.ReactNode;
}

const StyledCard = styled(Card)(({ theme }) => ({
  background:
    'linear-gradient(45deg, rgb(85, 178, 255) 30%, rgb(115, 208, 255) 90%)', // Adjusted to even lighter gradient colors

  color: theme.palette.common.white,
  boxShadow: theme.shadows[10],
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  minHeight: 150,
}));

const IconContainer = styled('div')({
  float: 'right',
});

const AggregateCard: React.FC<AggregateCardProps> = ({
  title,
  value,
  icon,
}) => {
  return (
    <StyledCard>
      <CardContent>
        <IconContainer>{icon}</IconContainer>
        <Typography variant='h5' component='div' gutterBottom>
          {title}
        </Typography>
        <Typography variant='h3' component='div'>
          {value}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default AggregateCard;
