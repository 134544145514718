import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ListFindingsPayload, ListFindings } from '../client/controlAnswer';

export interface ControlAnswerState {
  value: { [key: string]: any };
  status: string;
}

export const fetchControlAnswers: any = createAsyncThunk(
  'controlAnswers/fetch',
  async (payload: ListFindingsPayload) => {
    const response = await ListFindings(payload);

    const data = await response.data;
    return data;
  }
);

export const initialControlAnswerState: ControlAnswerState = {
  value: {},
  status: 'idle',
};

export const controlAnswerSlice = createSlice({
  name: 'controlAnswers',
  initialState: initialControlAnswerState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // List
      .addCase(fetchControlAnswers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchControlAnswers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchControlAnswers.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload;
      });
  },
});

export default controlAnswerSlice.reducer;
