import axios from 'axios';
import { UploadFilePayload } from '../redux/uploadURLSlice';
import { API_URL } from './models';

export const getS3URL = async (payload: { file: File }): Promise<any> => {
  console.log('S3_CLIENT:getUploadURL', JSON.stringify(payload.file, null, 2));

  const config = {
    method: 'post',
    url: `${API_URL}/policy/uploadURL`,
    headers: {},
    data: { fileName: payload.file.name, fileType: payload.file.type },
  };

  try {
    const uploadURLResponse = await axios(config);
    console.log('get URL response.data', uploadURLResponse.data);
    // console.log('pre-signed URL', response.data.uploadURL)
    // console.log('New file id', response.data.fileId)

    const putToS3Response = await axios.put(
      uploadURLResponse.data.uploadURL,
      payload.file,
      {
        headers: {
          'Content-Type': `${payload.file.type}`,
        },
      }
    );
    console.log('putToS3Response', putToS3Response);
    console.log('upload response.data', uploadURLResponse.data);

    return { success: true, data: uploadURLResponse.data };
  } catch (error: any) {
    if (error.name === 'AxiosError') {
      return { success: false, data: error.response.data };
    }
  }
};

export const uploadFileToS3 = async (
  payload: UploadFilePayload
): Promise<any> => {
  console.log('S3_CLIENT:uploadFileToS3');

  const config = {
    method: 'put',
    url: payload.preSignedURL,
    headers: {
      'Content-Type': payload.file.type,
    },
    data: payload.file,
  };

  try {
    const response = await axios(config);
    console.log(JSON.stringify('after s3 upload'));
    console.log('UPLOAD RESPONSE>data', JSON.stringify(response.data, null, 2));
    return { success: true, data: response.data };
  } catch (error: any) {
    if (error.name === 'AxiosError') {
      return { success: false, data: error.response.data };
    }
  }
};
export interface CreatePresignedURLPayload {
  organizationId: string;
  policyId: string;
  accessToken: string;
}

export const createPresignedURL = async ({
  organizationId,
  policyId,
  accessToken,
}: CreatePresignedURLPayload): Promise<any> => {
  console.log('S3_CLIENT:createPresignedURL');

  const config = {
    method: 'post',
    url: `${API_URL}/organization/${organizationId}/policy/${policyId}/presignedURL`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response = await axios(config);
    console.log('download URL', response.data);

    window.open(response.data, '_blank');
    return { success: true, data: response.data };
  } catch (error: any) {
    if (error.name === 'AxiosError') {
      return { success: false, data: error.response.data };
    }
  }
};
