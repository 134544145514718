import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import EvidenceComponent from './EvidenceComponent';
import { isEmpty, map } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

export default function RequirementEvidence({ answers }: { answers: any }) {
  if (isEmpty(answers)) <></>;

  const requirementAns = answers[0];
  // console.log('RequirementEvidence', answers);
  const requirement = useSelector(
    (state: RootState) => state.requirements.value[requirementAns.requirementId]
  );
  return (
    <Card
      sx={{
        margin: '8px',
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
        borderRadius: '12px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      }}
    >
      <CardHeader
        action={
          <Button
            variant='contained'
            disabled
            style={{
              marginRight: '10px',
              backgroundColor: '#1976D2',
              color: 'white',
              opacity: 0.5,
            }}
          >
            Req No. {requirement.reqNo}
          </Button>
        }
        // title={`${question}`}
        subheader={`${requirementAns.question}`}
      />
      {map(answers, (answer) => {
        return (
          <CardContent>
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ marginBottom: '20px', fontWeight: '500' }}
            >
              Policy: {answer.policyName}
            </Typography>
            <Typography
              variant='subtitle2'
              component='div'
              sx={{ marginBottom: '20px', fontWeight: '500' }}
            >
              Reasoning: {answer.reasoning}
            </Typography>
            {answer.evidence.map((evidence: any, index: number) => (
              <EvidenceComponent
                key={index}
                source={evidence.metadata.policyName}
                text={evidence.pageContent}
                snippetNumber={index}
              />
            ))}
          </CardContent>
        );
      })}
    </Card>
  );
}
