import { Box, CircularProgress } from '@mui/material';
import React from 'react';
// import { NavBar } from '../components/navigation/desktop/nav-bar';
// import { MobileNavBar } from '../components/navigation/mobile/mobile-nav-bar';

export const LoadingPage = () => {
  return (
    <Box>
      <CircularProgress />
    </Box>
  );
};
