import {
  Box,
  Toolbar,
  Container,
  Grid,
  Paper,
  CircularProgress,
  Typography,
} from '@mui/material';
import Chart from './Chart';
import SalesCard from './SalesCard';
import AggregateCard from './Cards/AggregateCard';
import SecurityIcon from '@mui/icons-material/Security';
import ScoreIcon from '@mui/icons-material/Score';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect } from 'react';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetrics } from '../../redux/metricsSlice';
import { isEqual, round } from 'lodash';
import Title from '../../components/Title';
import { useAuth0 } from '@auth0/auth0-react';

const identifyBreakdown = [
  { name: 'Asset Management', mitigated: 2, unmitigated: 5 },
  { name: 'Business Environment', mitigated: 2, unmitigated: 5 },
  { name: 'Governance', mitigated: 3, unmitigated: 1 },
  { name: 'Risk Assessment', mitigated: 5, unmitigated: 1 },
  { name: 'Risk Management Strategy', mitigated: 5, unmitigated: 1 },
  { name: 'Supply Chain Risk Management', mitigated: 10, unmitigated: 12 },
];

const protectBreakdown = [
  {
    name: 'Identity Management and Access Control',
    mitigated: 10,
    unmitigated: 5,
  },
  { name: 'Awareness and Training', mitigated: 5, unmitigated: 0 },
  { name: 'Data Security', mitigated: 5, unmitigated: 11 },
  {
    name: 'Information Protection Processes and Procedures',
    mitigated: 12,
    unmitigated: 5,
  },
  { name: 'Maintenance', mitigated: 8, unmitigated: 5 },
  { name: 'Protective Technology', mitigated: 9, unmitigated: 5 },
];

const detectBreakdown = [
  { name: 'Anomalies and Events', mitigated: 5, unmitigated: 8 },
  { name: 'Security Continuous Monitoring', mitigated: 2, unmitigated: 5 },
  { name: 'Detection Processes', mitigated: 3, unmitigated: 3 },
];

const respondBreakdown = [
  { name: 'Response Planning', mitigated: 0, unmitigated: 2 },
  { name: 'Communications', mitigated: 1, unmitigated: 1 },
  { name: 'Analysis', mitigated: 4, unmitigated: 1 },
  { name: 'Mitigation', mitigated: 8, unmitigated: 2 },
  { name: 'Improvements', mitigated: 1, unmitigated: 1 },
];

const recoverBreakdown = [
  { name: 'Recovery Planning', mitigated: 1, unmitigated: 5 },
  { name: 'Improvements', mitigated: 2, unmitigated: 1 },
  { name: 'Communications', mitigated: 1, unmitigated: 2 },
];

const paperStyles = {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  height: 300,
  justifyContent: 'center',
};

const pieChartPaperStyles = {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  height: 500,
  // paddingTop: "100px",
  // paddingBottom: "30px",
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const selectedOrg = useSelector(
    (state: RootState) => state.organizations.selectedOrganization
  );
  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      // const { data, error } = await getProtectedResource(accessToken);
      dispatch(fetchMetrics({ organizationId: selectedOrg.id, accessToken }));

      if (!isMounted) {
        return;
      }

      // if (data) {
      //   setMessage(JSON.stringify(data, null, 2));
      // }

      // if (error) {
      //   setMessage(JSON.stringify(error, null, 2));
      // }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, selectedOrg]);

  const metrics = useSelector((state: RootState) => state.metrics.value);

  const metricsStatus = useSelector((state: RootState) => state.metrics.status);
  const organizationStatus = useSelector(
    (state: RootState) => state.organizations.status
  );

  // useEffect(() => {
  //   dispatch(fetchMetrics({ organizationId: selectedOrg.id }));
  // }, [dispatch]);

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />

      <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
        {isEqual(metricsStatus, 'loading') ||
        isEqual(organizationStatus, 'loading') ? (
          <CircularProgress />
        ) : isEqual(metricsStatus, 'failed') ? (
          <Typography>Error loading metrics.</Typography>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <AggregateCard
                    title='AIA Score'
                    value={round(
                      (metrics.totals.mitigatedControls /
                        (metrics.totals.controls * 1.0)) *
                        100,
                      2
                    )}
                    icon={<ScoreIcon />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AggregateCard
                    title='Control Gaps'
                    value={metrics.totals.mitigatedControls}
                    icon={<CheckCircleIcon />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AggregateCard
                    title='Risk Mitigated'
                    value={metrics.totals.riskMitigated}
                    icon={<SecurityIcon />}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={2}>
                  <SalesCard
                    title={'Identify'}
                    gradient={
                      'linear-gradient(45deg, #8E2DE2 30%, #4A00E0 90%)'
                    }
                    totalSales={metrics.functions['Identify'].mitigated}
                    outOf={metrics.functions['Identify'].total}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SalesCard
                    title={'Protect'}
                    gradient={
                      'linear-gradient(45deg, #FF416C 30%, #FF4B2B 90%)'
                    }
                    totalSales={metrics.functions['Protect'].mitigated}
                    outOf={metrics.functions['Protect'].total}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SalesCard
                    title={'Detect'}
                    gradient={
                      'linear-gradient(45deg, #11998e 30%, #38ef7d 90%)'
                    }
                    totalSales={metrics.functions['Detect'].mitigated}
                    outOf={metrics.functions['Detect'].total}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SalesCard
                    title={'Respond'}
                    gradient={
                      'linear-gradient(45deg, #56CCF2 30%, #2F80ED 90%)'
                    }
                    totalSales={metrics.functions['Respond'].mitigated}
                    outOf={metrics.functions['Respond'].total}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SalesCard
                    title={'Recover'}
                    gradient={
                      'linear-gradient(45deg, #F2994A 30%, #F2C94C 90%)'
                    }
                    totalSales={metrics.functions['Recover'].mitigated}
                    outOf={metrics.functions['Recover'].total}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper sx={paperStyles}>
                <Title>Identify</Title>

                <Chart data={identifyBreakdown} />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper sx={paperStyles}>
                <Title>Protect</Title>

                <Chart data={protectBreakdown} />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper sx={paperStyles}>
                <Title>Detect</Title>

                <Chart data={detectBreakdown} />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper sx={paperStyles}>
                <Title>Respond</Title>
                <Chart data={respondBreakdown} />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper sx={paperStyles}>
                <Title>Recover</Title>
                <Chart data={recoverBreakdown} />
              </Paper>
            </Grid>

            {/* <Grid item xs={12} md={12} lg={12}>
            <Paper sx={pieChartPaperStyles}>
              <SubCatChart />
            </Paper>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Paper sx={paperStyles}>
              <GasGauge percentComplete={75} />
            </Paper>
          </Grid> */}

            {/* <Grid item xs={12} md={12} lg={12}>
            <Paper sx={paperStyles}>
              <Treemap
                width={400}
                height={200}
                data={data}
                dataKey='size'
                aspectRatio={4 / 3}
                stroke='#fff'
                fill='#8884d8'
              />
            </Paper>
          </Grid> */}
          </Grid>
        )}
      </Container>
    </Box>
  );
};
export default Dashboard;
