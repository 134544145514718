import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getS3URL, uploadFileToS3 } from '../client/s3';

export interface GetUploadURLPayload {
  file: File;
}
export interface UploadFilePayload {
  preSignedURL: string;
  file: File;
}

export const getUploadURL: any = createAsyncThunk(
  'uploadURL/uploadURL',
  async (payload: GetUploadURLPayload) => {
    const response = await getS3URL(payload);
    // const data = response.data;
    // console.log('upload resp', data);
    return response.data;
  }
);

// export const uploadFile: any = createAsyncThunk('uploadURL/uploadFile', async (payload: UploadFilePayload) => {
//     const response = await uploadFileToS3(payload)
//     const data = await response.data;
//     return data;
// });

export interface UploadURLState {
  value: { uploadUrl: string; fileId: string };
  status: string;
}
export const initialUploadURLState: UploadURLState = {
  value: { uploadUrl: '', fileId: '' }, // TODO: this is now storing the s3Key for the file
  status: 'idle',
};

export const uploadURLSlice = createSlice({
  name: 'uploadURL',
  initialState: initialUploadURLState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUploadURL.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUploadURL.fulfilled, (state, action) => {
        console.log('Before REDUCER', action.payload);
        state.status = 'idle';
        state.value = action.payload;
        console.log('After REDUCER', state.value);
      })
      .addCase(getUploadURL.rejected, (state, action) => {
        state.status = 'failed';
        state.value = action.payload;
      });

    // .addCase(uploadFile.pending, (state) => {
    //     state.status = 'loading';
    // })
    // .addCase(uploadFile.fulfilled, (state, action) => {
    //     state.status = 'idle';
    //     console.log('HERE!!!file uploaded to s3')
    //     // state.value = action.payload;
    // })
    // .addCase(uploadFile.rejected, (state, action) => {
    //     state.status = 'failed';
    //     state.value = action.payload;
    // });
  },
});

export default uploadURLSlice.reducer;
